<template>
  <div
   class="admin"
   :style="{ 'padding-left': paddingLeft }"
   v-loading.fullscreen.lock="!ready"
  >
    <ul
     class="nav"
     ref="nav"
    >
      <!-- <li class="default">
        <a href="/FindProspects/Home">{{$t('admin.backToClient')}}</a>
      </li> -->
      <router-link
       :to="{ path: '/' }"
       tag="li"
      >{{$t('admin.backToClient')}}</router-link>
      <router-link
       v-for="route in subRoutes"
       :active-class="'active'"
       :key="route.name"
       tag="li"
       :to="route"
      >{{$t(`router.${route.name}`)}}</router-link>
    </ul>
    <router-view v-if="ready"></router-view>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { routes } from '@/router'

export default {
  name: 'admin',
  data () {
    return {
      rendered: false
    }
  },
  computed: {
    ...mapGetters('admin', ['ready']),
    subRoutes () {
      return routes.find(route => route.name === 'admin').children
    },
    paddingLeft () {
      return this.rendered ? (parseInt(getComputedStyle(this.$refs.nav).width) + 20) + 'px' : 0
    }
  },
  methods: {
    ...mapActions({
      fetchSettings: 'admin/fetch'
    })
  },
  mounted () {
    this.rendered = true
    this.fetchSettings()
  }
}

</script>
<style scoped>
.admin {
  height: 100%;
  width: 100%;
  padding: 20px;
}

/* use /deep/ to affect child components, even though css is scoped */
/* https://bambielli.com/til/2018-08-19-how-to-target-child-components-with-scoped-css-in-vue/# */
/deep/ h1 {
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  margin-bottom: 20px;
  font-size: 2em;
}

.nav {
  list-style-type: none;
  width: auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  border-right: 1px solid #ccc;
  padding: 10px 0;
  background: white;
}

.nav li {
  padding: 10px;
}

.nav li.default,
.nav li.default:hover {
  background: white !important;
  cursor: default;
}

.nav li.active {
  background: #0a5e58;
  color: white;
}

.nav li:hover {
  background: #1e857e;
  cursor: pointer;
  color: white;
}

</style>
