<template>
  <div class="transparent-footer">
    <div
     class="transparent-background"
     :style="{ 'height': `${height}px` }"
    ></div>
    <footer id="footer">
      <slot></slot>
    </footer>
  </div>
</template>
<script>
import { ensureElement } from './utils'

export default {
  name: 'transparent-footer',
  data () {
    return {
      height: 0
    }
  },
  mounted () {
    ensureElement('footer').then(footer => {
      this.height = parseInt(getComputedStyle(footer).height)
    })
  }
}

</script>
<style scoped>
footer,
.transparent-background {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  box-sizing: border-box;
}

.transparent-background {
  background: white;
  opacity: 0.8;
}

</style>
