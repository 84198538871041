<template>
  <GenericModal
   title="Import person"
   class="import-dialog"
   :width="1000"
  >
    <div class="import-person">
      <el-form
       v-loading="loading"
       ref="mainForm"
       label-width="95px"
       label-position="left"
       :model="person"
       :rules="rules"
       :style="{ padding: '10px' }"
      >
        <div class="flag-and-name">
          <div class="flag-container">
            <flag
             :iso="countryAlphaByNum(person.country)"
             :squared="false"
             :title="countryNameByNum(person.country)"
            ></flag>
          </div>
          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item
               label-width="65px"
               prop="firstname"
              >
                <el-input
                 class="large-text"
                 v-model="person.firstname"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
               label-width="0px"
               prop="lastname"
              >
                <el-input
                 class="large-text"
                 v-model="person.lastname"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <br>
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item
             prop="email"
             label="E-mail"
            >
              <el-input v-model="person.email"></el-input>
            </el-form-item>
            <el-form-item
             prop="phone"
             label="Phone"
            >
              <el-input v-model="person.phone"></el-input>
            </el-form-item>
            <el-form-item
             prop="street_address"
             label="Home address"
            >
              <!-- <el-input v-model="person.street_address"></el-input> -->
              <AddressControl
               :address="person.street_address"
               :zipcode="person.street_zipcode"
               :city="person.street_city"
               prefix="street_"
               @change="handleAddressChange"
              ></AddressControl>
            </el-form-item>
            <el-form-item
             prop="url"
             label="Website"
            >
              <el-input v-model="person.url"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form
             ref="exportForm"
             :model="exportModel"
             :rules="exportRules"
             label-width="88px"
             hide-required-asterisk
            >
              <el-form-item
               prop="associate"
               label="Our contact"
              >
                <el-select
                 v-model="associate"
                 value-key="AssociateId"
                >
                  <el-option
                   v-for="a in associates"
                   :key="a.AssociateId"
                   :value="a.AssociateId"
                   :label="a.FullName"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
               prop="category"
               label="Category"
              >
                <el-select
                 v-model="category"
                 value-key="Id"
                >
                  <el-option
                   v-for="c in categories"
                   :key="c.Id"
                   :value="c.Id"
                   :label="c.Name"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
               prop="business"
               label="Business"
              >
                <el-select
                 v-model="business"
                 value-key="Id"
                >
                  <el-option
                   v-for="b in businesses"
                   :key="b.Id"
                   :value="b.Id"
                   :label="b.Name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <el-form-item
             prop="number"
             label="Number"
            >
              <el-input v-model="person.number"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <br>
      <el-collapse v-model="collapse">
        <el-collapse-item
         title="Custom fields"
         name="custom"
        >
          <el-card
           class="custom-card"
           shadow="never"
           v-loading="loading"
          >
            <el-form
             :model="person"
             label-position="top"
            >
              <el-row :gutter="30">
                <el-col
                 :span="12"
                 v-for="field in customFields"
                 :key="field.key"
                >
                  <el-form-item :label="field.label">
                    <el-select
                     v-if="field.type === 'list'"
                     v-model="person[field.key]"
                    >
                      <el-option
                       v-for="item in field.items"
                       :key="item.Id"
                       :value="item.Id"
                       :label="item.Name"
                      ></el-option>
                    </el-select>
                    <el-checkbox
                     v-else-if="field.type === 'bool'"
                     v-model="person[field.key]"
                    ></el-checkbox>
                    <el-input-number
                     v-else-if="field.type === 'number'"
                     v-model="person[field.key]"
                     :controls="false"
                    ></el-input-number>
                    <el-input
                     v-else
                     v-model="person[field.key]"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-card>
        </el-collapse-item>
      </el-collapse>
    </div>
    <el-dialog
     class="generic-modal"
     :visible.sync="duplicatesVisible"
     title="Possible duplicates"
     append-to-body
    >
      <el-table
       :data="person.duplicates"
       :cell-style="checkDuplicateField"
      >
        <el-table-column
         prop="Name"
         label="Name"
        ></el-table-column>
        <el-table-column
         prop="OrgNr"
         label="VATNo"
         width="80"
        ></el-table-column>
        <el-table-column
         prop="Address"
         label="Address"
        ></el-table-column>
        <el-table-column
         prop="Business"
         label="Business"
         width="120"
        ></el-table-column>
        <el-table-column
         prop="OurContact"
         label="Our contact"
         width="90"
        ></el-table-column>
        <el-table-column width="100">
          <template slot-scope="{ row }">
            <el-link
             :href="getDuplicateUrl(row)"
             target="_blank"
             icon="el-icon-view"
            >Open</el-link>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer">
        <el-button @click="duplicatesVisible = false">Hide</el-button>
      </span>
    </el-dialog>
    <template slot="footer">
      <el-button
       v-if="person.duplicates && person.duplicates.length"
       class="duplicates-btn"
       type="warning"
       @click="duplicatesVisible = true"
      >Show possible duplicates</el-button>
      <el-button
       type="success"
       :disabled="loading"
       @click="handleImport"
      >Import</el-button>
      <el-button
       type="success"
       :disabled="loading"
       @click="handleImportAndGoTo"
      >Import and go to</el-button>
    </template>
  </GenericModal>
</template>
<script>
import { get, sync, call } from 'vuex-pathify'

import GenericModal from '@/components/GenericModal'
import AddressControl from '@/components/AddressControl'

/**
 * Initialize import component view:
 * - Set local 'loading' state.
 * - Send request to '/api/prospect/convert' to convert person with established
 *   field mapping. Also fetches 'persons'.
 */
const init = async vm => {
  try {
    vm.loading = true
    vm.person = await vm.convertProspect(vm.prospectByUuid(vm.uuid))
    if (vm.person?.duplicates?.length) {
      // Show duplicate dialog
      vm.duplicatesVisible = true
    }
    setTimeout(() => {
      vm.loading = false
    }, 250)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default {
  components: {
    GenericModal,
    AddressControl
  },
  props: {
    uuid: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      duplicatesVisible: false,
      person: {},
      rules: {
        firstname: [{ required: true, trigger: 'blur', message: 'Firstname is required' }],
        lastname: [{ required: true, trigger: 'blur', message: 'Lastname is required' }],
        // associate: [{ required: true, trigger: 'blur', message: 'Our contact is required' }],
        // category: [{ required: true, trigger: 'blur', message: 'Category is required' }],
        // business: [{ required: true, trigger: 'blur', message: 'Business is required' }],
      },
      collapse: []
    }
  },
  computed: {
    ...get([
      'settings',
      'countryAlphaByNum',
      'countryNameByNum',
      'prospectByUuid',
      'associates',
      'categories',
      'businesses',
      'exportModel',
      'exportRules',
    ]),
    ...sync({
      associate: 'selected@associate',
      category: 'selected@category',
      business: 'selected@business'
    }),
    customFields () {
      return this.settings.PersonFields.filter(f => !f.standard)
    }
  },
  methods: {
    ...call([
      'notification',
      'convertProspect',
      'importProspects',
    ]),
    handleAddressChange ({ key, value }) {
      this.person[key] = value
    },
    async handleImport () {
      this.$refs.mainForm.validate(async valid => {
        if (valid) {
          this.$refs.exportForm.validate(async valid => {
            if (valid) {
              await this.importProspects({ data: this.person })
            }
          })
        }
      })
    },
    async handleImportAndGoTo () {
      this.$refs.mainForm.validate(async valid => {
        if (valid) {
          this.$refs.exportForm.validate(async valid => {
            if (valid) {
              await this.importProspects({ data: this.person, redirect: true })
            }
          })
        }
      })
    },
    checkDuplicateField ({ row, column }) {
      return row.DuplicateFields.includes(column.property)
        ? { color: 'red' }
        : {}
    },
    getDuplicateUrl (row) {
      return `${this.settings.SoProtocolBaseUrlDefault}person.main?${row.SoProtocolHint}`
    }
  },
  created () {
    init(this).catch(() => {
      this.$router.go(-1)
    })
  },
  beforeRouteUpdate (to, from, next) {
    init(this).then(() => {
      next()
    }).catch(() => {
      this.$router.replace({ name: 'MainPage' })
    })
  }
}

</script>
<style scoped>
.flag-and-name {
  position: relative;
  margin-bottom: 5px;
}

.flag-container {
  position: absolute;
  left: 0;
  font-size: 41px;
}

.persons-card>>>.el-card__body {
  padding: 0;
}

.persons-card>>>.el-table .cell {
  height: 25px;
}

.add-person-btn {
  margin: 5px 10px;
}

.duplicate-field {
  color: red;
}

.duplicates-btn {
  float: left;
}

</style>
