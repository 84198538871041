import { make } from 'vuex-pathify'
import contact from './contact'
import person from './person'

const state = {
  loading: true,
  error: null,
  useContact: false,
  usePerson: false
}

const getters = {
  ...make.getters(state),
  loading: (state, getters) => {
    return state.loading || (getters['contact/busy'] || getters['person/busy'])
  },
  ready: (state, getters) => {
    return getters['contact/ready'] || getters['person/ready']
  },
  general: (state, getters, rootState, rootGetters) => rootGetters.settings
}

const actions = {
  init: ({ commit, dispatch }, { contactId, personId }) => {
    return dispatch('contact/setExisting', contactId).then(response => {
      commit('useContact', response.contact)
      return dispatch('person/setExisting', { response, personId })
    }).then(response => {
      commit('usePerson', response.person)
      commit('loading', false)
    }).catch(err => {
      /* eslint-disable-next-line */
      console.error(err)
      commit('error', new Error(err))
    })
  }
}

const mutations = {
  ...make.mutations(state)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    contact,
    person
  }
}
