<template>
  <div
   class="update-existing"
   v-loading.fullscreen.lock="loading"
  >
    <router-view v-if="ready"></router-view>
    <div
     class="missing-current"
     v-else-if="error"
    >
      <h3>{{$t('update.noContext')}}</h3>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import qs from 'querystring'

export default {
  name: 'update-existing',
  data () {
    return {
      missingCurrent: false
    }
  },
  computed: {
    ...mapGetters('update', ['ready', 'loading', 'error'])
  },
  methods: {
    ...mapActions('update', [
      'init'
    ])
  },
  mounted () {
    const params = qs.decode(window.location.search.substr(1))
    const { contactId, personId } = params
    this.init({ contactId, personId })
  }
}

</script>
<style scoped>
.update-existing {
  width: 100%;
  height: 100%;
}

div.missing-current {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

h3 {
  color: #999;
}

</style>
