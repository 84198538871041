import admin from './admin'
import router from './router'
import update from './update'
import values from './values'

export default {
  admin,
  router,
  update,
  values
}
