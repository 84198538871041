export default function (id) {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      const elem = document.getElementById(id)
      if (elem) {
        clearInterval(interval)
        resolve(elem)
      }
    }, 50)
  })
}
