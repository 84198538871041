<template>
  <GenericModal
   :width="600"
   title="Import selected"
   class="import-dialog import-selected"
  >
    <p :style="{ marginBottom: '10px' }">You are importing {{prospects.length}} prospects (<b>double-click</b> row to remove):</p>
    <el-table
     class="selected-prospects"
     :data="prospects"
     :max-height="600"
     @row-dblclick="handleClearFromSelection"
    >
      <el-table-column
       prop="duplicates"
       width="46"
      >
        <template slot-scope="{ row }">
          <i
           v-if="row.fetchingDuplicates"
           class="duplicates-icon el-icon-loading"
          ></i>
          <i
           v-else-if="!row.duplicates"
           class="duplicates-icon el-icon-question"
          >
          </i>
          <el-tooltip
           v-else-if="row.duplicates && row.duplicates.length"
           :content="`${row.duplicates.length} possible duplicates`"
           placement="right"
          >
            <i class="duplicates-icon el-icon-warning"></i>
          </el-tooltip>
          <i
           v-if="row.imported"
           class="el-icon-check"
          ></i>
        </template>
      </el-table-column>
      <el-table-column
       prop="entity_type"
       label="Type"
       width="70"
      >
        <template slot-scope="{ row }">
          <i
           class="so-icon"
           :class="entityIcon(row.entity_type)"
          ></i>
        </template>
      </el-table-column>
      <el-table-column
       prop="name"
       label="Name"
      ></el-table-column>
      <el-table-column
       prop="org_no"
       label="VATNo"
       width="100"
      ></el-table-column>
    </el-table>
    <br>
    <el-form
     ref="form"
     label-width="110px"
     :model="model"
     :rules="rules"
    >
      <el-form-item
       label="Selection name"
       prop="selectionName"
      >
        <el-input v-model="selectionName"></el-input>
      </el-form-item>
      <el-form-item
       label="Our contact"
       prop="associate"
      >
        <el-select
         v-model="associate"
         value-key="AssociateId"
        >
          <el-option
           v-for="a in associates"
           :key="a.AssociateId"
           :value="a.AssociateId"
           :label="a.FullName"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
       label="Category"
       prop="category"
      >
        <el-select
         v-model="category"
         value-key="Id"
        >
          <el-option
           v-for="c in categories"
           :key="c.Id"
           :value="c.Id"
           :label="c.Name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
       label="Business"
       prop="business"
      >
        <el-select
         v-model="business"
         value-key="Id"
        >
          <el-option
           v-for="b in businesses"
           :key="b.Id"
           :value="b.Id"
           :label="b.Name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Include persons">
        <el-radio-group v-model="includePersons">
          <el-radio
           v-for="option in includePersonsOptions"
           :key="option.value"
           :label="option.value"
          >{{option.label}}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template slot="footer">
      <el-button
       type="success"
       :disabled="!ready || loading"
       @click="handleImport"
      >Import</el-button>
      <el-button
       type="success"
       :disabled="!ready || loading"
       @click="handleImportAndGoTo"
      >Import and go to</el-button>
    </template>
  </GenericModal>
</template>
<script>
import { get, sync, call } from 'vuex-pathify'
import GenericModal from '@/components/GenericModal'

export default {
  components: {
    GenericModal
  },
  data () {
    return {
      ready: false,
      loading: false,
      rules: {
        selectionName: [{ required: true, message: 'Please input a selection name', trigger: 'blur' }],
        associate: [{ required: true, message: 'Our contact is required', trigger: 'change' }],
        category: [{ required: true, message: 'Category is required', trigger: 'change' }],
        business: [{ required: true, message: 'Business is required', trigger: 'change' }],
      },
      includePersonsOptions: [{
        label: 'First only',
        value: 1
      }, {
        label: 'All',
        value: 2
      }, {
        label: 'None',
        value: 3
      }]
    }
  },
  computed: {
    ...get([
      'entityIcon',
      'associates',
      'categories',
      'businesses'
    ]),
    ...sync({
      selectionName: 'selected@selectionName',
      associate: 'selected@associate',
      category: 'selected@category',
      business: 'selected@business',
      includePersons: 'selected@includePersons',
      prospects: 'selected@prospects',
    }),
    model () {
      const { selectionName, associate, category, business } = this
      return { selectionName, associate, category, business }
    }
  },
  methods: {
    ...call([
      'checkForDuplicates',
      'importProspects'
    ]),
    handleClearFromSelection (row) {
      // this.prospects = this.prospects.filter(p => p.uuid !== row.uuid)
      this.$emit('clear-from-selection', row)
    },
    async handleImport () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            await this.importProspects({ data: this.prospects })
            this.$emit('imported', this.prospects)
            this.$router.go(-1)
          } catch (error) {
            console.error(error)
          }
        }
      })
    },
    async handleImportAndGoTo () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          await this.importProspects({ data: this.prospects, redirect: true })
          this.$emit('imported', this.prospects)
        }
      })
    },
  },
  async mounted () {
    // Define missing duplicate checks for selected prospects
    // - .filter out prospects where duplicates == null
    // - 'async await' loop over result, fetching duplicates
    const prospectsMissingDuplicates = this.prospects.filter(p => !p.duplicates)
    for (const prospect of prospectsMissingDuplicates) {
      await this.checkForDuplicates(prospect)
    }
    this.ready = true
  }
}

</script>
<style scoped>
.selected-prospects>>>.cell {
  height: 15px;
  line-height: 15px;
}

.selected-prospects>>>.so-icon {
  height: 15px;
  width: 15px;
  background-size: 15px 15px;
}

.import-selected>>>.el-form-item.is-error {
  margin-bottom: 10px;
  transition: height 0.5s ease;
}

</style>
