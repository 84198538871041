import UpdateExisting from '@/views/UpdateExisting'
import InitiateSearch from '@/views/UpdateExisting/InitiateSearch'
import ContactIndex from '@/views/UpdateExisting/Contact'
import ContactSearchResults from '@/views/UpdateExisting/Contact/SearchResults'
import ContactSelectValues from '@/views/UpdateExisting/Contact/SelectValues'
import PersonIndex from '@/views/UpdateExisting/Person'
import PersonSearchResults from '@/views/UpdateExisting/Person/SearchResults'
import PersonSelectValues from '@/views/UpdateExisting/Person/SelectValues'

export default {
  path: '/update-existing',
  component: UpdateExisting,
  children: [{
    path: '',
    name: 'update-existing',
    component: InitiateSearch
  }, {
    path: '/contact',
    name: 'contact',
    component: ContactIndex,
    children: [{
      path: 'search-results',
      name: 'contact-search-results',
      component: ContactSearchResults
    }, {
      path: ':uuid',
      name: 'contact-select-values',
      component: ContactSelectValues,
      props: true
    }]
  }, {
    path: '/person',
    name: 'person',
    component: PersonIndex,
    children: [{
      path: 'search-results',
      name: 'person-search-results',
      component: PersonSearchResults
    }, {
      path: ':uuid',
      name: 'person-select-values',
      component: PersonSelectValues,
      props: true
    }]
  }]
}
