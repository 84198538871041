import Admin from '@/views/Admin'
// import GeneralSettings from '@/views/Admin/GeneralSettings'
import DataProviders from '@/views/Admin/DataProviders'
import DataProvider from '@/views/Admin/DataProviders/DataProvider'

import store from '@/store'

export default {
  path: '/admin',
  name: 'admin',
  redirect: '/admin/data-providers',
  component: Admin,
  children: [{
    path: 'data-providers',
    name: 'data-providers',
    label: 'Data Providers',
    component: DataProviders,
    children: [{
      path: ':id',
      name: 'provider',
      component: DataProvider,
      beforeEnter: (to, from, next) => {
        const { id } = to.params
        const provider = store.getters['admin/provider/byProgId'](id)
        if (!provider) {
          const error = new Error(`No provider exists with ID '${id}'`)
          error.domain = 'admin'
          error.fallback = 'data-providers'
          next(error)
        } else {
          store.dispatch('admin/provider/current', provider)
          next(provider)
        }
      }
    }]
  }]
}

// {
//   path: 'general-settings',
//   name: 'general-settings',
//   label: 'General settings',
//   component: GeneralSettings
// }
