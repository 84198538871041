<template>
  <GenericModal
   title="Import company"
   class="import-dialog"
   :width="1000"
  >
    <div class="import-company">
      <el-form
       ref="mainForm"
       label-width="88px"
       label-position="left"
       v-loading="loading"
       :rules="rules"
       :model="company"
       :style="{ padding: '10px' }"
      >
        <div class="flag-and-name">
          <div class="flag-container">
            <flag
             :iso="countryAlphaByNum(company.country)"
             :squared="false"
             :title="countryNameByNum(company.country)"
            ></flag>
          </div>
          <el-form-item
           prop="name"
           label-width="65px"
          >
            <el-input
             class="large-text"
             v-model="company.name"
            ></el-input>
          </el-form-item>
          <el-form-item
           prop="department"
           label-width="65px"
          >
            <el-input v-model="company.department"></el-input>
          </el-form-item>
        </div>
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item
             prop="street_address"
             label="Visiting"
            >
              <AddressControl
               :address="company.street_address"
               :zipcode="company.street_zipcode"
               :city="company.street_city"
               prefix="street_"
               @change="handleAddressChange"
              ></AddressControl>
            </el-form-item>
            <el-form-item
             prop="postal_address"
             label="Postal"
            >
              <AddressControl
               :address="company.postal_address"
               :zipcode="company.postal_zipcode"
               :city="company.postal_city"
               prefix="postal_"
               @change="handleAddressChange"
              ></AddressControl>
            </el-form-item>
            <el-form-item
             prop="phone"
             label="Phone"
            >
              <el-input v-model="company.phone"></el-input>
            </el-form-item>
            <el-form-item
             prop="fax"
             label="Fax"
            >
              <el-input v-model="company.fax"></el-input>
            </el-form-item>
            <el-form-item
             prop="url"
             label="Website"
            >
              <el-input v-model="company.url"></el-input>
            </el-form-item>
            <el-form-item
             prop="email"
             label="Email"
            >
              <el-input v-model="company.email"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form
             ref="exportForm"
             :model="exportModel"
             :rules="exportRules"
             label-width="88px"
             hide-required-asterisk
            >
              <el-form-item
               prop="associate"
               label="Our contact"
              >
                <el-select
                 v-model="associate"
                 value-key="AssociateId"
                >
                  <el-option
                   v-for="a in associates"
                   :key="a.AssociateId"
                   :value="a.AssociateId"
                   :label="a.FullName"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
               prop="category"
               label="Category"
              >
                <el-select
                 v-model="category"
                 value-key="Id"
                >
                  <el-option
                   v-for="c in categories"
                   :key="c.Id"
                   :value="c.Id"
                   :label="c.Name"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
               prop="business"
               label="Business"
              >
                <el-select
                 v-model="business"
                 value-key="Id"
                >
                  <el-option
                   v-for="b in businesses"
                   :key="b.Id"
                   :value="b.Id"
                   :label="b.Name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <el-form-item
             prop="code"
             label="Code"
            >
              <el-input v-model="company.code"></el-input>
            </el-form-item>
            <el-form-item
             prop="number"
             label="Number"
            >
              <el-input v-model="company.number"></el-input>
            </el-form-item>
            <el-form-item
             prop="org_no"
             label="Org.nr"
            >
              <el-input v-model="company.org_no"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <br>
      <el-collapse v-model="collapse">
        <el-collapse-item
         title="Custom fields"
         name="custom"
        >
          <el-card
           class="custom-card"
           shadow="never"
           v-loading="loading"
          >
            <el-form
             :model="company"
             label-position="top"
            >
              <el-row :gutter="30">
                <el-col
                 v-for="field in customFields"
                 :span="12"
                 :key="field.key"
                >
                  <el-form-item :label="field.label">
                    <el-select
                     v-if="field.type === 'list'"
                     v-model="company[field.key]"
                    >
                      <el-option
                       v-for="item in field.items"
                       :key="item.Id"
                       :value="item.Id"
                       :label="item.Name"
                      ></el-option>
                    </el-select>
                    <el-checkbox
                     v-else-if="field.type === 'bool'"
                     v-model="company[field.key]"
                    ></el-checkbox>
                    <el-input-number
                     v-else-if="field.type === 'number'"
                     v-model="company[field.key]"
                     :controls="false"
                    ></el-input-number>
                    <el-input
                     v-else
                     v-model="company[field.key]"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-card>
        </el-collapse-item>
        <el-collapse-item
         title="Persons"
         name="persons"
        >
          <el-card
           class="persons-card"
           shadow="never"
           v-loading="loading"
          >
            <el-table
             ref="persons"
             :data="company.persons"
             :empty-text="personsEmptyText"
            >
              <el-table-column
               prop="Firstname"
               label="Firstname"
              >
                <template slot-scope="{ row }">
                  <el-input v-model="row.Firstname"></el-input>
                </template>
              </el-table-column>
              <el-table-column
               prop="Lastname"
               label="Lastname"
              >
                <template slot-scope="{ row }">
                  <el-input v-model="row.Lastname"></el-input>
                </template>
              </el-table-column>
              <el-table-column
               prop="Email"
               label="E-mail"
              >
                <template slot-scope="{ row }">
                  <el-input v-model="row.Email"></el-input>
                </template>
              </el-table-column>
              <el-table-column
               prop="Phone"
               label="Phone"
              >
                <template slot-scope="{ row }">
                  <el-input v-model="row.Phone"></el-input>
                </template>
              </el-table-column>
              <el-table-column
               prop="Title"
               label="Title"
              >
                <template slot-scope="{ row }">
                  <el-input v-model="row.Title"></el-input>
                </template>
              </el-table-column>
              <el-table-column width="60">
                <template slot-scope="{ row }">
                  <el-button
                   class="delete-button"
                   type="danger"
                   icon="el-icon-delete-solid"
                   @click="deletePerson(row)"
                  >
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-button
             class="add-person-btn"
             type="primary"
             icon="el-icon-plus"
             @click="addPerson"
            ></el-button>
          </el-card>
        </el-collapse-item>
      </el-collapse>
    </div>
    <el-dialog
     class="generic-modal"
     :visible.sync="duplicatesVisible"
     title="Possible duplicates"
     append-to-body
    >
      <el-table
       :data="company.duplicates"
       :cell-style="checkDuplicateField"
      >
        <el-table-column
         prop="Name"
         label="Name"
        ></el-table-column>
        <el-table-column
         prop="OrgNr"
         label="VATNo"
         width="80"
        ></el-table-column>
        <el-table-column
         prop="Address"
         label="Address"
        ></el-table-column>
        <el-table-column
         prop="Business"
         label="Business"
         width="120"
        ></el-table-column>
        <el-table-column
         prop="OurContact"
         label="Our contact"
         width="90"
        ></el-table-column>
        <el-table-column width="100">
          <template slot-scope="{ row }">
            <el-link
             :href="getDuplicateUrl(row)"
             target="_blank"
             icon="el-icon-view"
            >Open</el-link>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer">
        <el-button @click="duplicatesVisible = false">Hide</el-button>
      </span>
    </el-dialog>
    <template slot="footer">
      <el-button
       v-if="company.duplicates && company.duplicates.length"
       class="duplicates-btn"
       type="warning"
       @click="duplicatesVisible = true"
      >Show possible duplicates</el-button>
      <el-button
       type="success"
       :disabled="loading"
       @click="handleImport"
      >Import</el-button>
      <el-button
       type="success"
       :disabled="loading"
       @click="handleImportAndGoTo"
      >Import and go to</el-button>
    </template>
  </GenericModal>
</template>
<script>
import { get, sync, call } from 'vuex-pathify'
import { v4 as uuid } from 'uuid'

import GenericModal from '@/components/GenericModal'
import AddressControl from '@/components/AddressControl'

/**
 * Initialize import component view:
 * - Set local 'loading' state.
 * - Send request to '/api/prospect/convert' to convert company with established
 *   field mapping. Also fetches 'persons'.
 */
const init = async vm => {
  try {
    vm.loading = true
    vm.company = await vm.convertProspect(vm.prospectByUuid(vm.uuid))
    if (vm.company.persons.length) {
      // Toggle selection of all persons
      vm.$refs.persons.toggleAllSelection()
      vm.personsEmptyText = 'Not importing any persons. Click [+] below to add persons manually.'
    }
    if (vm.company.duplicates.length) {
      // Show duplicate dialog
      vm.duplicatesVisible = true
    }
    setTimeout(() => {
      vm.loading = false
    }, 250)
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * Create a new person object
 */
const createPerson = () => ({
  Firstname: '',
  Lastname: '',
  Email: '',
  Phone: '',
  Title: '',
  uuid: uuid()
})

export default {
  components: {
    GenericModal,
    AddressControl
  },
  props: {
    uuid: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      duplicatesVisible: false,
      personsEmptyText: 'No persons found. Click [+] below to add persons manually.',
      company: {},
      rules: {
        name: [{ required: true, trigger: 'blur', message: 'Name is required' }]
      },
      collapse: ['persons']
    }
  },
  computed: {
    ...get([
      'settings',
      'countryAlphaByNum',
      'countryNameByNum',
      'prospectByUuid',
      'associates',
      'categories',
      'businesses',
      'exportModel',
      'exportRules',
    ]),
    ...sync({
      associate: 'selected@associate',
      category: 'selected@category',
      business: 'selected@business'
    }),
    customFields () {
      return this.settings.CompanyFields.filter(f => !f.standard)
    }
  },
  methods: {
    ...call([
      'notification',
      'convertProspect',
      'importProspects',
    ]),
    addPerson () {
      this.company.persons.push(createPerson())
    },
    deletePerson (person) {
      const index = this.company.persons.findIndex(p => p.uuid === person.uuid)
      if (index > -1) {
        this.company.persons.splice(index, 1)
      }
    },
    handleAddressChange ({ key, value }) {
      this.company[key] = value
    },
    async handleImport () {
      this.$refs.mainForm.validate(async valid => {
        if (valid) {
          this.$refs.exportForm.validate(async valid => {
            if (valid) {
              await this.importProspects({ data: this.company })
            }
          })
        }
      })
    },
    async handleImportAndGoTo () {
      this.$refs.mainForm.validate(async valid => {
        if (valid) {
          this.$refs.exportForm.validate(async valid => {
            if (valid) {
              await this.importProspects({ data: this.company, redirect: true })
            }
          })
        }
      })
    },
    checkDuplicateField ({ row, column }) {
      return row.DuplicateFields.includes(column.property)
        ? { color: 'red' }
        : {}
    },
    getDuplicateUrl (row) {
      return `${this.settings.SoProtocolBaseUrlDefault}contact.main?${row.SoProtocolHint}`
    }
  },
  created () {
    init(this).catch(() => {
      this.$router.go(-1)
    })
  },
  beforeRouteUpdate (to, from, next) {
    init(this).then(() => {
      next()
    }).catch(() => {
      this.$router.replace({ name: 'MainPage' })
    })
  }
}

</script>
<style scoped>
.flag-and-name {
  position: relative;
  margin-bottom: 5px;
}

.flag-container {
  position: absolute;
  left: 0;
  font-size: 41px;
}

.persons-card>>>.el-card__body {
  padding: 0;
}

.persons-card>>>.el-table .cell {
  height: 25px;
}

.add-person-btn {
  margin: 5px 10px;
}

.duplicate-field {
  color: red;
}

.duplicates-btn {
  float: left;
}

</style>
