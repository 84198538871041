export default {
  noContext: 'No current Company or Person in context...',
  noContact: 'No contact in context',
  noPerson: 'No person in context',
  searchContact: 'Look for updates for current company',
  searchPerson: 'Look for updates for current contact',
  noResultsTitle: 'No results',
  noResultsMessage: 'Could not find any results for',
  fields: {
    name: 'Name',
    firstname: 'Firstname',
    lastname: 'Lastname',
    org_no: 'VATNo',
    phone: 'Phone number',
    url: 'Web site',
    street_address: 'Street address',
    street_zipcode: 'Zipcode',
    street_city: 'City',
    postal_address: 'Postal address',
    postal_zipcode: 'Zipcode',
    postal_city: 'City',
    source: 'Provider'
  },
  selectColumns: {
    field: 'Field',
    existing: 'Current value',
    select: 'Should update',
    new: 'Update to'
  },
  update: 'Update',
  cancel: 'Cancel'
}
