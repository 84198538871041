export default [
  // 'IconHint',
  // 'ExternalId',
  // 'EntityType',
  'name',
  'org_no',
  'phone',
  'url',
  'street_address',
  'street_zipcode',
  'street_city',
  'postal_address',
  'postal_zipcode',
  'postal_city',
  // 'BusinessName',
  // 'Department',
  // 'Associate',
  // 'Category',
  // 'Business',
  // 'People',
  // 'Source',
  // 'SectorCode',
  // 'IndustryCode'
  // 'uuid'
]
