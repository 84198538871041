<template>
  <div class="search-results">
    <el-table
     :data="options"
     size="mini"
     @row-click="goToValues"
     :style="{ width: '100%' }"
    >
      <el-table-column
       :label="$t('update.fields.firstname')"
       prop="firstname"
       sortable
      ></el-table-column>
      <el-table-column
       :label="$t('update.fields.lastname')"
       prop="lastname"
       sortable
      ></el-table-column>
      <el-table-column
       :label="$t('update.fields.street_address')"
       prop="street_address"
       sortable
      ></el-table-column>
      <el-table-column
       :label="$t('update.fields.street_zipcode')"
       prop="street_zipcode"
       sortable
      ></el-table-column>
      <el-table-column
       :label="$t('update.fields.street_city')"
       prop="street_city"
       sortable
      ></el-table-column>
      <el-table-column
       :label="$t('update.fields.source')"
       prop="source"
       sortable
      ></el-table-column>
    </el-table>
    <!-- <table class="super-office footer-space">
      <thead>
        <tr>
          <th>{{$t('update.fields.firstname')}}</th>
          <th>{{$t('update.fields.lastname')}}</th>
          <th>{{$t('update.fields.street_address')}}</th>
          <th class="align-right">{{$t('update.fields.street_zipcode')}}</th>
          <th>{{$t('update.fields.street_city')}}</th>
          <th>{{$t('update.fields.source')}}</th>
        </tr>
      </thead>
      <tbody>
        <tr
         v-for="option in options"
         :key="option.uuid"
         @click="goToValues(option)"
        >
          <td>{{option.firstname}}</td>
          <td>{{option.lastname}}</td>
          <td>{{option.street_address}}</td>
          <td class="align-right">{{option.street_zipcode}}</td>
          <td>{{option.street_city}}</td>
          <td>{{option.source}}</td>
        </tr>
      </tbody>
    </table> -->
    <transparent-footer>
      <el-button
       type="default"
       @click="cancel"
      >{{$t('update.cancel')}}</el-button>
    </transparent-footer>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import TransparentFooter from '@/components/TransparentFooter'

export default {
  name: 'search-results',
  components: {
    TransparentFooter
  },
  computed: {
    ...mapGetters('update/person', ['options'])
  },
  methods: {
    ...mapActions('update/person', ['convert']),
    cancel () {
      this.$router.go(-1)
    },
    goToValues (option) {
      this.convert(option).then(() => {
        this.$router.push({ name: 'person-select-values', params: { uuid: option.uuid } })
      })
    }
  },
  created () {
    const { options } = this
    if (options.length === 1) {
      this.goToValues(options[0])
      // this.$router.replace({ name: 'person-select-values', params: { uuid: options[0].uuid } })
    }
  }
}

</script>
<style scoped>
.search-results {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

</style>
