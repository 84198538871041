<template>
  <div
   id="app"
   v-loading.fullscreen.lock="loading"
  >
    <transition
     name="fade"
     mode="out-in"
    >
      <router-view v-if="ready"></router-view>
    </transition>
  </div>
</template>
<script>
import { get, sync, call } from 'vuex-pathify'

export default {
  name: 'find-prospects',
  computed: {
    ...get(['loading', 'notifications', 'messages']),
    ...sync(['ready'])
  },
  watch: {
    notifications (array) {
      const latest = array[array.length - 1]
      this.$notify({
        duration: 3000,
        ...latest
      })
    },
    messages (array) {
      const latest = array[array.length - 1]
      this.$message({
        duration: 3000,
        ...latest
      })
    }
  },
  methods: {
    ...call(['init'])
  },
  created () {
    this.init().then(locale => {
      this.$i18n.locale = locale
      this.ready = true
    })
  }
}

</script>
<style>
#app {
  height: 100%;
  width: 100%;
}

/* transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

</style>
