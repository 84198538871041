<template>
  <el-dialog
   v-if="provider"
   class="data-provider"
   :visible="visible"
   :title="provider.DisplayName || 'N/A'"
   @close="close"
   @closed="reset"
   width="1000px"
   top="40px"
   v-loading="loading"
  >
    <el-tabs
     v-model="activeTab"
     type="card"
    >
      <el-tab-pane
       label="Company mapping"
       name="company-mapping"
      >
        <h4 class="sub-header">Standard fields</h4>
        <el-form
         class="mapping-form"
         size="mini"
         label-position="top"
        >
          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="Name">
                <el-select
                 :value="provider.CompanyMapping['name']"
                 value-key="key"
                 @change="handleChange('company', 'name', $event)"
                >
                  <el-option
                   v-for="option in getFieldsByType(provider.CompanyFields, 'string')"
                   :label="option.label"
                   :value="option.key"
                   :key="option.key"
                   :disabled="isChosen(provider.CompanyMapping, option.key)"
                  ></el-option>
                  <p
                   slot="empty"
                   class="el-select-dropdown__empty"
                  >No available fields for mapping</p>
                </el-select>
              </el-form-item>
              <el-form-item label="Department">
                <el-select
                 :value="provider.CompanyMapping['department']"
                 value-key="key"
                 clearable
                 @change="handleChange('company', 'department', $event)"
                >
                  <el-option
                   v-for="option in getFieldsByType(provider.CompanyFields, 'string')"
                   :label="option.label"
                   :value="option.key"
                   :key="option.key"
                   :disabled="isChosen(provider.CompanyMapping, option.key)"
                  ></el-option>
                  <p
                   slot="empty"
                   class="el-select-dropdown__empty"
                  >No available fields for mapping</p>
                </el-select>
              </el-form-item>
              <el-form-item label="Street address">
                <el-select
                 :value="provider.CompanyMapping['street_address']"
                 value-key="key"
                 clearable
                 @change="handleChange('company', 'street_address', $event)"
                >
                  <el-option
                   v-for="option in getFieldsByType(provider.CompanyFields, 'string')"
                   :label="option.label"
                   :value="option.key"
                   :key="option.key"
                   :disabled="isChosen(provider.CompanyMapping, option.key)"
                  ></el-option>
                  <p
                   slot="empty"
                   class="el-select-dropdown__empty"
                  >No available fields for mapping</p>
                </el-select>
              </el-form-item>
              <el-form-item label="Street zipcode">
                <el-select
                 :value="provider.CompanyMapping['street_zipcode']"
                 value-key="key"
                 clearable
                 @change="handleChange('company', 'street_zipcode', $event)"
                >
                  <el-option
                   v-for="option in getFieldsByType(provider.CompanyFields, 'string')"
                   :label="option.label"
                   :value="option.key"
                   :key="option.key"
                   :disabled="isChosen(provider.CompanyMapping, option.key)"
                  ></el-option>
                  <p
                   slot="empty"
                   class="el-select-dropdown__empty"
                  >No available fields for mapping</p>
                </el-select>
              </el-form-item>
              <el-form-item label="Street city">
                <el-select
                 :value="provider.CompanyMapping['street_city']"
                 value-key="key"
                 clearable
                 @change="handleChange('company', 'street_city', $event)"
                >
                  <el-option
                   v-for="option in getFieldsByType(provider.CompanyFields, 'string')"
                   :label="option.label"
                   :value="option.key"
                   :key="option.key"
                   :disabled="isChosen(provider.CompanyMapping, option.key)"
                  ></el-option>
                  <p
                   slot="empty"
                   class="el-select-dropdown__empty"
                  >No available fields for mapping</p>
                </el-select>
              </el-form-item>
              <el-form-item label="Postal address">
                <el-select
                 :value="provider.CompanyMapping['postal_address']"
                 value-key="key"
                 clearable
                 @change="handleChange('company', 'postal_address', $event)"
                >
                  <el-option
                   v-for="option in getFieldsByType(provider.CompanyFields, 'string')"
                   :label="option.label"
                   :value="option.key"
                   :key="option.key"
                   :disabled="isChosen(provider.CompanyMapping, option.key)"
                  ></el-option>
                  <p
                   slot="empty"
                   class="el-select-dropdown__empty"
                  >No available fields for mapping</p>
                </el-select>
              </el-form-item>
              <el-form-item label="Postal zipcode">
                <el-select
                 :value="provider.CompanyMapping['postal_zipcode']"
                 value-key="key"
                 clearable
                 @change="handleChange('company', 'postal_zipcode', $event)"
                >
                  <el-option
                   v-for="option in getFieldsByType(provider.CompanyFields, 'string')"
                   :label="option.label"
                   :value="option.key"
                   :key="option.key"
                   :disabled="isChosen(provider.CompanyMapping, option.key)"
                  ></el-option>
                  <p
                   slot="empty"
                   class="el-select-dropdown__empty"
                  >No available fields for mapping</p>
                </el-select>
              </el-form-item>
              <el-form-item label="Postal city">
                <el-select
                 :value="provider.CompanyMapping['postal_city']"
                 value-key="key"
                 clearable
                 @change="handleChange('company', 'postal_city', $event)"
                >
                  <el-option
                   v-for="option in getFieldsByType(provider.CompanyFields, 'string')"
                   :label="option.label"
                   :value="option.key"
                   :key="option.key"
                   :disabled="isChosen(provider.CompanyMapping, option.key)"
                  ></el-option>
                  <p
                   slot="empty"
                   class="el-select-dropdown__empty"
                  >No available fields for mapping</p>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Phone">
                <el-select
                 :value="provider.CompanyMapping['phone']"
                 value-key="key"
                 clearable
                 @change="handleChange('company', 'phone', $event)"
                >
                  <el-option
                   v-for="option in getFieldsByType(provider.CompanyFields, 'string')"
                   :label="option.label"
                   :value="option.key"
                   :key="option.key"
                   :disabled="isChosen(provider.CompanyMapping, option.key)"
                  ></el-option>
                  <p
                   slot="empty"
                   class="el-select-dropdown__empty"
                  >No available fields for mapping</p>
                </el-select>
              </el-form-item>
              <el-form-item label="Website">
                <el-select
                 :value="provider.CompanyMapping['url']"
                 value-key="key"
                 clearable
                 @change="handleChange('company', 'url', $event)"
                >
                  <el-option
                   v-for="option in getFieldsByType(provider.CompanyFields, 'string')"
                   :label="option.label"
                   :value="option.key"
                   :key="option.key"
                   :disabled="isChosen(provider.CompanyMapping, option.key)"
                  ></el-option>
                  <p
                   slot="empty"
                   class="el-select-dropdown__empty"
                  >No available fields for mapping</p>
                </el-select>
              </el-form-item>
              <el-form-item label="Code">
                <el-select
                 :value="provider.CompanyMapping['code']"
                 value-key="key"
                 clearable
                 @change="handleChange('company', 'code', $event)"
                >
                  <el-option
                   v-for="option in getFieldsByType(provider.CompanyFields, 'string')"
                   :label="option.label"
                   :value="option.key"
                   :key="option.key"
                   :disabled="isChosen(provider.CompanyMapping, option.key)"
                  ></el-option>
                  <p
                   slot="empty"
                   class="el-select-dropdown__empty"
                  >No available fields for mapping</p>
                </el-select>
              </el-form-item>
              <el-form-item label="Number">
                <el-select
                 :value="provider.CompanyMapping['number']"
                 value-key="key"
                 clearable
                 @change="handleChange('company', 'number', $event)"
                >
                  <el-option
                   v-for="option in getFieldsByType(provider.CompanyFields, 'string')"
                   :label="option.label"
                   :value="option.key"
                   :key="option.key"
                   :disabled="isChosen(provider.CompanyMapping, option.key)"
                  ></el-option>
                  <p
                   slot="empty"
                   class="el-select-dropdown__empty"
                  >No available fields for mapping</p>
                </el-select>
              </el-form-item>
              <el-form-item label="Org.nr">
                <el-select
                 :value="provider.CompanyMapping['org_no']"
                 value-key="key"
                 clearable
                 @change="handleChange('company', 'org_no', $event)"
                >
                  <el-option
                   v-for="option in getFieldsByType(provider.CompanyFields, 'string')"
                   :label="option.label"
                   :value="option.key"
                   :key="option.key"
                   :disabled="isChosen(provider.CompanyMapping, option.key)"
                  ></el-option>
                  <p
                   slot="empty"
                   class="el-select-dropdown__empty"
                  >No available fields for mapping</p>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider :style="{ margin: '0 0 16px' }"></el-divider>
        <h4 class="sub-header">Custom fields</h4>
        <el-form
         class="mapping-form"
         size="mini"
         label-position="top"
        >
          <el-row :gutter="15">
            <el-col
             v-for="field in companyExtraFields"
             :span="12"
             :key="field.key"
            >
              <el-form-item :label="field.label">
                <el-select
                 clearable
                 value-key="key"
                 :value="provider.CompanyMapping[field.key]"
                 :style="{ width: '100%' }"
                 @change="handleChange('company', field.key, $event)"
                >
                  <el-option
                   v-for="option in getFieldsByType(provider.CompanyFields, field.type)"
                   :label="option.label"
                   :value="option.key"
                   :key="option.key"
                   :disabled="isChosen(provider.CompanyMapping, option.key)"
                  ></el-option>
                  <p
                   slot="empty"
                   class="el-select-dropdown__empty"
                  >No available fields for mapping</p>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider :style="{ margin: '0 0 16px' }"></el-divider>
        <el-row>
          <el-button
           type="default"
           @click="resetCompanyMapping"
          >Reset mapping to default</el-button>
        </el-row>
      </el-tab-pane>
      <el-tab-pane
       v-if="provider.PersonFields.length"
       label="Person mapping"
       name="person-mapping"
      >
        <h4 class="sub-header">Standard fields</h4>
        <el-form
         class="mapping-form"
         size="mini"
         label-position="top"
        >
          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="Firstname">
                <el-select
                 :value="provider.PersonMapping['firstname']"
                 value-key="key"
                 @change="handleChange('person', 'firstname', $event)"
                >
                  <el-option
                   v-for="option in getFieldsByType(provider.PersonFields, 'string')"
                   :label="option.label"
                   :value="option.key"
                   :key="option.key"
                   :disabled="isChosen(provider.PersonMapping, option.key)"
                  ></el-option>
                  <p
                   slot="empty"
                   class="el-select-dropdown__empty"
                  >No available fields for mapping</p>
                </el-select>
              </el-form-item>
              <el-form-item label="Lastname">
                <el-select
                 :value="provider.PersonMapping['lastname']"
                 value-key="key"
                 clearable
                 @change="handleChange('person', 'lastname', $event)"
                >
                  <el-option
                   v-for="option in getFieldsByType(provider.PersonFields, 'string')"
                   :label="option.label"
                   :value="option.key"
                   :key="option.key"
                   :disabled="isChosen(provider.PersonMapping, option.key)"
                  ></el-option>
                  <p
                   slot="empty"
                   class="el-select-dropdown__empty"
                  >No available fields for mapping</p>
                </el-select>
              </el-form-item>
              <el-form-item label="Title">
                <el-select
                 :value="provider.PersonMapping['title']"
                 value-key="key"
                 clearable
                 @change="handleChange('person', 'title', $event)"
                >
                  <el-option
                   v-for="option in getFieldsByType(provider.PersonFields, 'string')"
                   :label="option.label"
                   :value="option.key"
                   :key="option.key"
                   :disabled="isChosen(provider.PersonMapping, option.key)"
                  ></el-option>
                  <p
                   slot="empty"
                   class="el-select-dropdown__empty"
                  >No available fields for mapping</p>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Home address">
                <el-select
                 :value="provider.PersonMapping['street_address']"
                 value-key="key"
                 clearable
                 @change="handleChange('person', 'street_address', $event)"
                >
                  <el-option
                   v-for="option in getFieldsByType(provider.PersonFields, 'string')"
                   :label="option.label"
                   :value="option.key"
                   :key="option.key"
                   :disabled="isChosen(provider.PersonMapping, option.key)"
                  ></el-option>
                  <p
                   slot="empty"
                   class="el-select-dropdown__empty"
                  >No available fields for mapping</p>
                </el-select>
              </el-form-item>
              <el-form-item label="Zipcode">
                <el-select
                 :value="provider.PersonMapping['street_zipcode']"
                 value-key="key"
                 clearable
                 @change="handleChange('person', 'street_zipcode', $event)"
                >
                  <el-option
                   v-for="option in getFieldsByType(provider.PersonFields, 'string')"
                   :label="option.label"
                   :value="option.key"
                   :key="option.key"
                   :disabled="isChosen(provider.PersonMapping, option.key)"
                  ></el-option>
                  <p
                   slot="empty"
                   class="el-select-dropdown__empty"
                  >No available fields for mapping</p>
                </el-select>
              </el-form-item>
              <el-form-item label="City">
                <el-select
                 :value="provider.PersonMapping['street_city']"
                 value-key="key"
                 clearable
                 @change="handleChange('person', 'street_city', $event)"
                >
                  <el-option
                   v-for="option in getFieldsByType(provider.PersonFields, 'string')"
                   :label="option.label"
                   :value="option.key"
                   :key="option.key"
                   :disabled="isChosen(provider.PersonMapping, option.key)"
                  ></el-option>
                  <p
                   slot="empty"
                   class="el-select-dropdown__empty"
                  >No available fields for mapping</p>
                </el-select>
              </el-form-item>
              <el-form-item label="Number">
                <el-select
                 :value="provider.PersonMapping['number']"
                 value-key="key"
                 clearable
                 @change="handleChange('person', 'number', $event)"
                >
                  <el-option
                   v-for="option in getFieldsByType(provider.PersonFields, 'string')"
                   :label="option.label"
                   :value="option.key"
                   :key="option.key"
                   :disabled="isChosen(provider.PersonMapping, option.key)"
                  ></el-option>
                  <p
                   slot="empty"
                   class="el-select-dropdown__empty"
                  >No available fields for mapping</p>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider :style="{ margin: '0 0 16px' }"></el-divider>
        <h4 class="sub-header">Custom fields</h4>
        <el-form
         class="mapping-form"
         size="mini"
         label-position="top"
        >
          <el-row :gutter="15">
            <el-col
             v-for="field in personExtraFields"
             :span="12"
             :key="field.key"
            >
              <el-form-item :label="field.label">
                <el-select
                 clearable
                 value-key="key"
                 :value="provider.PersonMapping[field.key]"
                 :style="{ width: '100%' }"
                 @change="handleChange('person', field.key, $event)"
                >
                  <el-option
                   v-for="option in getFieldsByType(provider.PersonFields, field.type)"
                   :label="option.label"
                   :value="option.key"
                   :key="option.key"
                   :disabled="isChosen(provider.PersonMapping, option.key)"
                  ></el-option>
                  <p
                   slot="empty"
                   class="el-select-dropdown__empty"
                  >No available fields for mapping</p>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider :style="{ margin: '0 0 16px' }"></el-divider>
        <el-row>
          <el-button
           type="default"
           @click="resetPersonMapping"
          >Reset mapping to default</el-button>
        </el-row>
      </el-tab-pane>
    </el-tabs>
    <span slot="footer">
      <el-button
       type="success"
       @click="handleSave"
      >Save</el-button>
      <el-button @click="close">Cancel</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { cloneDeep } from 'lodash'

import LinkMobility from './LinkMobility'
import BrReg from './BrReg'

export default {
  name: 'data-provider',
  components: {
    [LinkMobility.name]: LinkMobility,
    [BrReg.name]: BrReg
  },
  data () {
    return {
      activeTab: 'company-mapping',
      visible: true,
      test: '',
      provider: null
    }
  },
  computed: {
    ...mapGetters('admin', ['soFields']),
    ...mapGetters('admin/provider', ['loading']),
    companyExtraFields () {
      return this.soFields.company.filter(i => !i.standard)
    },
    personExtraFields () {
      return this.soFields.person.filter(i => !i.standard)
    }
  },
  methods: {
    ...mapMutations('admin/provider', {
      setCurrent: 'current',
      setCompanyMapping: 'setCompanyMapping',
      setPersonMapping: 'setPersonMapping'
    }),
    ...mapActions('admin/provider', ['save']),
    handleSave () {
      this.save(this.provider).then(() => {
        this.close()
      })
    },
    close () {
      this.visible = false
    },
    reset () {
      this.$router.go(-1) // go back
      this.setCurrent(null)
    },
    getFieldsByType (array, type) {
      // If target field is 'string', return every mappable field
      return type === 'string' ? array : array.filter(i => i.type === type)
    },
    isChosen (collection, providerKey) {
      return Object.values(collection).indexOf(providerKey) > -1
    },
    handleChange (target, soKey, providerKey) {
      const value = providerKey || ''
      if (target === 'company') {
        this.$set(this.provider.CompanyMapping, soKey, value)
      } else if (target === 'person') {
        this.$set(this.provider.PersonMapping, soKey, value)
      }
    },
    resetCompanyMapping () {
      const { provider } = this
      for (const key in provider.CompanyMapping) {
        const std = (provider.CompanyFields.find(i => i.key === key) || {}).standard
        this.$set(this.provider.CompanyMapping, key, std)
      }
      this.$message('Company mapping reset to standard. Click Save to apply.')
    },
    resetPersonMapping () {
      const { provider } = this
      for (const key in provider.PersonMapping) {
        const std = (provider.PersonFields.find(i => i.key === key) || {}).standard
        this.$set(this.provider.PersonMapping, key, std)
      }
      this.$message('Person mapping reset to standard. Click Save to apply.')
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.provider = cloneDeep(vm.$store.getters['admin/provider/current'])
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.provider = cloneDeep(this.$store.getters['admin/provider/current'])
  }
}

</script>
<style scoped>
.data-provider>>>.el-dialog__body {
  padding: 3px 20px 20px;
}

.data-provider>>>.el-divider--horizontal {
  margin: 20px 0 15px;
  background-color: #e8e8e8;
}

.data-provider>>>.el-tabs__content {
  padding: 0 10px;
}

.data-provider>>>.el-form-item {
  margin-bottom: 7px;
}

.data-provider>>>.el-form-item__label {
  padding-right: 5px;
}

.sub-header {
  margin-bottom: 10px;
}

.form-headers>>>.el-form-item__label,
.form-headers>>>.el-form-item__content {
  color: #aaa;
  font-size: 12px;
}

.mapping-form>>>.el-form-item {
  margin: 0;
}

.mapping-form>>>.el-form-item__label {
  padding: 0;
  line-height: 15px;
  font-size: 0.9em;
  margin-bottom: 2px;
}

</style>
