export default [{
  label: 'People & Companies',
  config: {
    Companies: true,
    People: true
  }
}, {
  label: 'Companies',
  config: {
    Companies: true,
    People: false
  }
}, {
  label: 'People',
  config: {
    Companies: false,
    People: true
  }
}]
