<template>
  <div class="initiate-search">
    <el-card :style="{ paddingBottom: '20px' }">
      <img
       class="logo"
       :src="logo"
       alt="Logo"
      >
      <div
       v-if="useContact"
       class="button-wrapper push-down"
      >
        <el-button
         class="search"
         type="primary"
         size="medium"
         plain
         :disabled="!contact"
         @click="doSearchContact"
        >
          <img
           class="button-icon"
           :src="contactImg"
           alt="(C)"
          >
          {{$t('update.searchContact')}}
        </el-button>
        <p
         class="descriptor"
         :class="{ 'error': !contact }"
        >{{contactText}}</p>
      </div>
      <div
       v-else
       class="button-wrapper push-down"
      >
        <el-button
         class="search"
         type="primary"
         size="medium"
         plain
         @click="doSearchPerson"
         :disabled="!person"
        >
          <img
           class="button-icon"
           :src="personImg"
           alt="(P)"
          >
          {{$t('update.searchPerson')}}
        </el-button>
        <p
         class="descriptor"
         :class="{ 'error': !person }"
        >{{personText}}</p>
      </div>
    </el-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import logo from '@/assets/logo.png'
import contactImg from '@/assets/contact.png'
import personImg from '@/assets/person.png'

export default {
  name: 'initiate-search',
  data () {
    return {
      logo,
      contactImg,
      personImg
    }
  },
  computed: {
    ...mapGetters('update', {
      useContact: 'useContact',
      contact: 'contact/current',
      person: 'person/current'
    }),
    contactText () {
      const { contact } = this
      return contact ? `${contact.name}` : this.$t('update.noContact')
    },
    personText () {
      const { person } = this
      return person ? `${person.firstname} ${person.lastname}` : this.$t('update.noPerson')
    }
  },
  methods: {
    ...mapActions('update', {
      getContactOptions: 'contact/getOptions',
      getPersonOptions: 'person/getOptions'
    }),
    doSearchContact () {
      this.getContactOptions().then(() => {
        this.$router.push({ path: '/contact/search-results' })
      }).catch(err => {
        this.$notify.info({
          title: this.$t('update.noResultsTitle'),
          message: `${this.$t('update.noResultsMessage')} ${err.message}`
        })
      })
    },
    doSearchPerson () {
      this.getPersonOptions().then(() => {
        this.$router.push({ path: '/person/search-results' })
      }).catch(err => {
        this.$notify.info({
          title: this.$t('update.noResultsTitle'),
          message: `${this.$t('update.noResultsMessage')} ${err.message}`
        })
      })
    }
  }
}

</script>
<style scoped>
.initiate-search {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo {
  display: block;
  width: 225px;
  margin: 0 auto;
}

.search {
  position: relative;
  display: block;
  margin-left: 0;
  padding-left: 32px;
  z-index: 1;
  width: 100%;
}

.button-icon {
  position: absolute;
  left: 7px;
  top: 6px;
}

.descriptor {
  padding: 6px;
  background: #f7f7f7;
  color: #789;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.3px;
  max-width: 90%;
  margin: 0 auto;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  z-index: 0;
}

.error {
  background: #fff0f0;
  border-color: #ddd;
  color: #bbb;
}

</style>
