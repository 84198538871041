import http from '@/services/http'
import countries from 'i18n-iso-countries'

import { make } from 'vuex-pathify'

import provider from './modules/provider'

const state = {
  loading: false,
  ready: false
}

const getters = {
  ...make.getters(state),
  getCountryAlphaByNum: _ => num => countries.numericToAlpha2(num),
  getCountryNameByNum: _ => num => countries.getName(num, 'en'),
  soFields: (state, getters, rootState, rootGetters) => {
    const { settings } = rootGetters
    return { company: settings.CompanyFields, person: settings.PersonFields }
  },
  mandatoryCompanyOnPerson: (state, getters, rootState, rootGetters) => {
    return rootGetters.settings.MandatoryCompanyOnPerson
  },
  importPersonAs: (state, getters, rootState, rootGetters) => {
    return rootGetters.settings.ImportPersonAs
  }
}

const actions = {
  fetch: ({ commit, dispatch }) => {
    dispatch('provider/all').then(() => {
      commit('ready', true)
    })
  },
  setImportPersonAs: ({ commit, getters }, importPersonAs) => {
    const old = getters.importPersonAs
    commit('importPersonAs', importPersonAs, { root: true })
    return http.put('/api/admin/general', {
      ImportPersonAs: importPersonAs
    }).catch(err => {
      console.error(err)
      commit('importPersonAs', old, { root: true })
    })
  }
}

const mutations = {
  ...make.mutations(state), // vuex-pathify: generate based on state
  // additional mutations
  general: (state, payload) => {
    state.importPersonAs = payload.ImportPersonAs
    state.mandatoryCompanyOnPerson = payload.MandatoryCompanyOnPerson
    state.soFields.company = payload.CompanyFields
    state.soFields.person = payload.PersonFields
  },
  toggleProvider: (state, { index, checked }) => {
    state.providers[index].IsActive = checked
  },
  setCurrentProvider: (state, provider) => {
    state.currentProvider = provider
  },
  saveProviderConfiguration: (state, { index, provider }) => {
    state.providers.splice(index, 1, provider)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    provider
  }
}
