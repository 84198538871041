<template>
  <div
   class="address-control"
   v-click-outside="handleClickOutside"
  >
    <a
     class="display"
     @click="handleDisplayClick"
    >{{display}}</a>
    <el-card
     v-show="showInputs"
     class="inputs"
    >
      <el-row :gutter="15">
        <el-col :span="24">
          <el-input
           ref="address"
           :value="address"
           @input="handleInput('address', $event)"
          ></el-input>
        </el-col>
        <el-col :span="12">
          <el-input
           :value="zipcode"
           @input="handleInput('zipcode', $event)"
          ></el-input>
        </el-col>
        <el-col :span="12">
          <el-input
           :value="city"
           @input="handleInput('city', $event)"
          ></el-input>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import vClickOutside from 'v-click-outside'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    address: String,
    zipcode: String,
    city: String,
    prefix: String
  },
  data () {
    return {
      showInputs: false
    }
  },
  computed: {
    display () {
      const { address: a, zipcode: z, city: c } = this
      return ((a && a) || '') + // Street address
        ((a && (z || c)) && ', ') + // Separating comma (if 'a' AND 'z OR c')
        ((z && `${z} `) || '') + // Zipcode (with space: `${z}_`)
        ((c && c) || '') || // City
        'Click to set address' // Default value
    }
  },
  methods: {
    handleDisplayClick () {
      this.showInputs = true
      this.$nextTick(() => {
        // On next render tick, set focus on address input
        this.$refs.address.focus()
      })
    },
    handleClickOutside () {
      this.showInputs = false
    },
    handleInput (key, value) {
      // Emit event with payload { key: 'address|zipcode|city', value: value }
      this.$emit('change', { key: `${this.prefix}${key}`, value })
    }
  }
}

</script>
<style scoped>
.address-control {
  position: relative;
}

.display {
  color: #277abe;
  font-size: 13px;
  padding-left: 7px;
}

.display:hover {
  color: #003366;
  text-decoration: underline;
  cursor: pointer;
}

.inputs {
  position: absolute;
  z-index: 1500;
  top: 90%;
}

.inputs>>>.el-card__body {
  padding: 7px 7px 10px;
}

</style>
