export const isEqual = (value, otherValue) => {
  // remove inherent whitespace (" ")
  const updateTo = typeof otherValue === 'string' ? otherValue.trim() : otherValue
  if (!updateTo) {
    console.log(value)
    return true
  } else if (!value) {
    return !updateTo
  } else if (typeof updateTo === 'string') {
    // string = case insensitive comparison
    return value.trim().toLowerCase() === updateTo.trim().toLowerCase()
  } else {
    return value === updateTo
  }
}

export const objectsAreEqual = (properties, ex, nw) => {
  return properties.every(property => {
    return isEqual(ex[property], nw[property], true)
  })
}
