import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en'
import no from './no'

Vue.use(VueI18n)

const messages = { en, no }

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
})
