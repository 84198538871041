<template>
  <div class="person flex-start">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'person'
}

</script>
<style scoped>
</style>
