<template>
  <div class="data-providers">
    <h1>{{$t('router.data-providers')}}</h1>
    <el-card shadow="never">
      <div class="spacious">
        <label :style="{ display: 'block', marginBottom: '3px' }">{{$t('admin.importPersonAs')}}</label>
        <el-tooltip
         effect="dark"
         placement="bottom"
         :disabled="!mandatoryCompanyOnPerson"
        >
          <span slot="content">
            {{$t('admin.mandatoryCompanyOnPersonRequired')}}
          </span>
          <el-select
           :style="{ width: '250px' }"
           :value="importPersonAs"
           @change="handleImportPersonAsChange"
           :disabled="mandatoryCompanyOnPerson"
          >
            <el-option
             v-for="option in options"
             :key="option.value"
             :value="option.value"
             :label="$t(`values.${option.value}`)"
            ></el-option>
          </el-select>
        </el-tooltip>
      </div>
      <el-divider></el-divider>
      <h2 class="so-label-heading">{{$t('admin.chooseWhichProviders')}}</h2>
      <el-row>
        <el-col
         :span="12"
         class="provider-box"
         v-for="provider in all"
         :key="provider.ProgId"
        >
          <el-card :shadow="provider === current ? 'always' : 'hover'">
            <el-switch
             :name="provider.ProgId"
             :value="provider.IsActive"
             :active-text="provider.DisplayName"
             active-color="#0a5e58"
             @change="handleToggleProvider(provider, $event)"
            ></el-switch>
            <!-- <label :for="provider.progId">{{provider.DisplayName}}</label> -->
            <p class="description">{{provider.Description}}</p>
            <div
             class="flag-container inline-block"
             v-for="num in provider.CountryIds"
             :key="num"
            >
              <flag
               :iso="getCountryAlphaByNum(num)"
               :squared="false"
               :title="getCountryNameByNum(num)"
              ></flag>
            </div>
            <span class="configure-btn-container">
              <el-button
               type="primary"
               @click="toggleConfigureProvider(provider)"
              >Configure</el-button>
            </span>
          </el-card>
        </el-col>
        <!-- Mock Provider for error testing -->
        <!-- <el-col
       :span="12"
       class="provider-box"
       key="error"
      >
        <el-card shadow="hover">
          <el-switch
           :name="mockProviderError.ProgId"
           :value="mockProviderError.IsActive"
           active-color="#13ce66"
           disabled
           @change="handleToggleProvider(mockProviderError, $event)"
          ></el-switch>
          <label :for="mockProviderError.progId">{{mockProviderError.DisplayName}}</label>
          <p class="description">{{mockProviderError.Description}}</p>
          <div
           class="flag-container inline-block"
           v-for="num in mockProviderError.CountryIds"
           :key="num"
          >
            <flag
             :iso="getCountryAlphaByNum(num)"
             :squared="false"
             :title="getCountryNameByNum(num)"
            ></flag>
          </div>
          <span class="configure-btn-container">
            <el-button
             type="primary"
             @click="toggleConfigureProvider(mockProviderError)"
            >Configure</el-button>
          </span>
        </el-card>
      </el-col> -->
      </el-row>
    </el-card>
    <router-view />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { sync } from 'vuex-pathify'

export default {
  name: 'data-providers',
  data () {
    return {
      options: [{
        label: 'Company and person',
        value: 'company_and_person'
      }, {
        label: 'Person',
        value: 'person'
      }],
      mockProviderError: {
        ProgId: 'error',
        DisplayName: 'error',
        Description: 'Click this for an error',
        fields: [],
        CountryIds: [578],
        IsActive: false,
        IsHidden: false
      }
    }
  },
  computed: {
    ...mapGetters('admin', [
      'getCountryAlphaByNum',
      'getCountryNameByNum',
    ]),
    ...mapGetters('admin/provider', ['all', 'current']),
    ...mapGetters('admin', ['mandatoryCompanyOnPerson']),
    importPersonAs: sync('settings@ImportPersonAs')
  },
  methods: {
    ...mapActions('admin/provider', ['toggleActive']),
    ...mapActions('admin/provider', {
      setCurrent: 'current'
    }),
    ...mapActions('admin', ['setImportPersonAs']),
    handleImportPersonAsChange (value) {
      this.setImportPersonAs(value)
    },
    handleToggleProvider (provider, checked) {
      this.toggleActive({ provider, checked })
    },
    toggleConfigureProvider (provider) {
      this.$router.push({ name: 'provider', params: { id: provider.ProgId } })
    }
  }
}

</script>
<style scoped>
.data-providers {
  max-width: 1100px;
}

ul {
  list-style-type: none;
}

.provider-box {
  position: relative;
  padding: 5px;
  margin-bottom: 10px;
}

.provider-box .el-switch,
.provider-box label,
.provider-box .description {
  margin-right: 7px;
  margin-bottom: 7px;
}

.so-label-heading {
  color: #696969;
  font-weight: bold;
  font-size: larger;
  margin-bottom: 10px;
}

.description {
  color: #808080;
}

.flag-container {
  font-size: 15px;
}

.configure-btn-container {
  position: absolute;
  right: 15px;
  top: 15px;
}

.spacious {
  padding: 0 15px 0 10px;
}

.spacious * {
  margin-right: 6px;
}

select {
  font-family: 'Segoe UI', 'Arial', 'Helvetica'
}

</style>
