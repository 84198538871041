<template>
  <el-row
   type="flex"
   justify="center"
   align="middle"
   :style="{ minHeight: '100px' }"
  >
    <i :style="{ color: '#666', fontSize: '1.1em' }">No general settings to configure.</i>
  </el-row>
</template>
<script>
import * as utils from './utils'

export default {
  name: 'brreg',
  props: {
    provider: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...utils
  }
}

</script>
<style>
</style>
