import Vue from 'vue'
import Router from 'vue-router'

import MainPage from '@/views/MainPage'
import SendFeedback from '@/views/SendFeedback'
import ImportCompany from '@/views/Import/ImportCompany'
import ImportPerson from '@/views/Import/ImportPerson'
import ImportSelected from '@/views/Import/ImportSelected'

import admin from './admin'
import update from './update'

Vue.use(Router)

export const routes = [{
  path: '/',
  name: 'MainPage',
  component: MainPage,
  children: [{
    path: 'company/:uuid',
    name: 'ImportCompany',
    type: 'contact',
    component: ImportCompany,
    props: true
  }, {
    path: 'person/:uuid',
    name: 'ImportPerson',
    type: 'person',
    component: ImportPerson,
    props: true
  }, {
    path: 'multiple',
    name: 'ImportSelected',
    type: 'multiple',
    component: ImportSelected
  }, {
    path: 'feedback',
    name: 'SendFeedback',
    component: SendFeedback
  }]
}, admin, update]

export const importViews =
  routes.find(r => r.name === 'MainPage').children.filter(c => !!c.type)

export default new Router({ routes })
