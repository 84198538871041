import http from '@/services/http'
import { make } from 'vuex-pathify'

const evaluateFieldsAndMapping = (mapping, fields, keys) => {
  return keys.reduce((results, key) => {
    const mapped = mapping[key]
    if (mapped) {
      results[key] = mapped
    } else {
      results[key] = (fields.find(i => i.standard === key) || {}).key
    }
    return results
  }, {})
}

const initiateProviderMappings = (provider, { soCompanyKeys, soPersonKeys }) => {
  const { CompanyMapping, CompanyFields, PersonMapping, PersonFields } = provider
  return {
    ...provider,
    CompanyMapping: evaluateFieldsAndMapping(CompanyMapping, CompanyFields, soCompanyKeys),
    PersonMapping: evaluateFieldsAndMapping(PersonMapping, PersonFields, soPersonKeys)
  }
}

const state = {
  loading: false,
  all: [],
  current: null
}

const getters = {
  ...make.getters(state),
  byProgId: state => progId => state.all.find(i => i.ProgId === progId)
}

/* eslint no-console: ["error", { "allow": ["error"] }] */
const actions = {
  all: ({ commit, rootGetters }) => {
    const { company, person } = rootGetters['admin/soFields']
    const soCompanyKeys = company.map(i => i.key)
    const soPersonKeys = person.map(i => i.key)
    return http.get('/api/admin/provider').then(response => {
      const providers = response.data.map(prov => {
        return initiateProviderMappings(prov, { soCompanyKeys, soPersonKeys })
      })
      commit('all', providers)
    })
  },
  toggleActive: ({ commit, getters }, { provider, checked }) => {
    commit('toggleActive', { provider, checked })
    return http.put('/api/admin/provider', { ...provider, IsActive: checked }).catch(err => {
      console.error(err)
      commit('toggleActive', { provider, checked: !checked })
    })
  },
  current: ({ commit, getters }, provider) => {
    return new Promise((resolve, reject) => {
      if (provider) {
        commit('current', provider)
        resolve(provider)
      } else {
        reject(new Error(`No provider exists with ID '${provider.ProgId}'`))
      }
    })
  },
  save: ({ dispatch, commit, getters }, provider) => {
    commit('loading', true)
    return http.put('/api/admin/provider', provider).then(response => {
      commit('save', { provider })
      commit('loading', false)
      commit('notification', {
        title: 'Success!',
        message: 'Provider mapping saved.',
        type: 'success'
      }, { root: true })
    })
  }
}

const mutations = {
  ...make.mutations(state), // vuex-pathify: generate based on state
  // additional mutations
  toggleActive: (state, { provider, checked }) => {
    const index = state.all.findIndex(i => i.ProgId === provider.ProgId)
    state.all[index].IsActive = checked
  },
  current: (state, provider) => {
    state.current = provider
  },
  save: (state, { provider }) => {
    const index = state.all.findIndex(i => i.ProgId === provider.ProgId)
    state.all.splice(index, 1, provider)
  },
  setCompanyMapping: (state, { providerProgId, providerKey, superOfficeKey }) => {
    const index = state.all.findIndex(i => i.ProgId === providerProgId)
    state.all[index].CompanyMapping[superOfficeKey] = providerKey
  },
  setPersonMapping: (state, { providerProgId, providerKey, superOfficeKey }) => {
    const index = state.all.findIndex(i => i.ProgId === providerProgId)
    state.all[index].PersonMapping[superOfficeKey] = providerKey
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
