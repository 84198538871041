<template>
  <el-dialog
   class="generic-modal"
   :top="top.toString()"
   :title="title"
   :visible.sync="dialogVisible"
   :width="myWidth"
   @closed="handleClosed"
  >
    <slot></slot>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{cancelText}}</el-button>
      <slot name="footer"></slot>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    width: {
      type: [String, Number],
      default: '50%'
    },
    title: {
      type: String,
      required: true
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    top: {
      type: [String, Number],
      default: '30px'
    }
  },
  data () {
    return {
      dialogVisible: false
    }
  },
  computed: {
    myWidth () {
      const { width: w } = this
      return typeof w === 'number' ? `${w}px` : w
    }
  },
  methods: {
    handleClosed () {
      this.$router.go(-1)
    }
  },
  mounted () {
    this.dialogVisible = true
  }
}

</script>
<style scoped>
/* .generic-modal>>>.el-dialog {
  max-height: 90%;
}

.generic-modal>>>.el-dialog__body {
  overflow-y: auto;
} */

</style>
