<template>
  <el-select
   class="select-country"
   value-key="value"
   :value="value"
   @change="$emit('input', $event)"
  >
    <span slot="prefix">
      <flag
       class="country-option__flag"
       :iso="getAlpha2ByNumCode(value)"
       :squared="false"
       :title="value.toString()"
      ></flag>
    </span>
    <el-option
     class="country-option"
     v-for="country in options"
     :key="country.value"
     :value="country.value"
     :label="country.label"
    >
      <flag
       class="country-option__flag"
       :iso="getAlpha2ByNumCode(country.value)"
       :squared="false"
       :title="country.label"
      ></flag>
      <span class="country-option__label">{{country.label}}</span>
    </el-option>
  </el-select>
</template>
<script>
import isoCountries from 'i18n-iso-countries'

export default {
  props: {
    value: {
      type: Number,
      required: true
    },
    countries: {
      type: Array,
      required: true
    },
    numCodeKey: {
      type: String,
      required: true
    },
    labelKey: {
      type: String,
      default: 'label'
    }
  },
  computed: {
    options () {
      const { countries, numCodeKey, labelKey } = this
      return countries.map(c => ({ value: Number(c[numCodeKey]), label: c[labelKey] }))
    }
  },
  methods: {
    getAlpha2ByNumCode (numCode) {
      return isoCountries.numericToAlpha2(numCode)
    }
  }
}

</script>
<style scoped>
.country-option {}

.country-option__flag {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-bottom: 4px;
}

.country-option__label {
  display: inline-block;
  margin-left: 10px;
}

</style>
