export default {
  ClientCrossMessaging: {
    sendCommand (command, args) {
      parent.postMessage({ command, arguments: args }, '*')
    },
    executeSoProtocol (protocol) {
      this.sendCommand('soprotocol', protocol)
    },
    refresh () {
      this.sendCommand('refresh')
    }
  }
}
