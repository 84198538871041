import axios from 'axios'
import Bottleneck from 'bottleneck'

const stopAndCreateNewLimiter = async (limiter, settings) => {
  await limiter.stop({
    dropWaitingJobs: true,
    dropErrorMessage: 'Forced dropping of queued jobs. Ignore this error.'
  })
  return new Bottleneck(settings)
}

const bottleneckSettings = {
  maxConcurrent: 1, // Execute 1 HTTP request at a time
  minTime: 333 // Execute max 3 requests per second
}

/**
 * Tried to remove Bottleneck (and just use 'async await' with for-of), but
 * using Bottleneck greatly increases frontend responsiveness, and does not
 * overload the browser with requests that make the GUI laggy/choppy. It gives
 * re-painting and other render activities some resources to work with.
 */
let limiter = new Bottleneck(bottleneckSettings)

// // Bottleneck Development logger
// setInterval(() => {
//   console.log(limiter.counts())
// }, 3000)

let token = ''
try {
  /* eslint-disable-next-line */
  //token = CSRFToken
  token = 'TODO'
} catch (error) {
  console.error(new Error('Missing CSRFToken'))
}

const client = axios.create({
  baseURL: '/FindProspects',
  headers: {
    RequestVerificationToken: token
  }
})

export default {
  limiter: () => limiter,
  get: async (url, config = {}) => {
    if (config.stopSchedule) {
      limiter = await stopAndCreateNewLimiter(limiter, bottleneckSettings)
    }
    const priority = config.priority || 9
    return limiter.schedule({ priority }, () => client.get(url, config))
  },
  post: async (url, data, config = {}) => {
    if (config.stopSchedule) {
      limiter = await stopAndCreateNewLimiter(limiter, bottleneckSettings)
    }
    const priority = config.priority || 9
    return limiter.schedule({ priority }, () => client.post(url, data, config))
    // return client.post(url, data, config)
  },
  put: async (url, data, config = {}) => {
    const priority = config.priority || 9
    if (config.stopSchedule) {
      limiter = await stopAndCreateNewLimiter(limiter, bottleneckSettings)
    }
    return limiter.schedule({ priority }, () => client.put(url, data, config))
  },
  patch: async (url, data, config = {}) => {
    const priority = config.priority || 9
    if (config.stopSchedule) {
      limiter = await stopAndCreateNewLimiter(limiter, bottleneckSettings)
    }
    return limiter.schedule({ priority }, () => client.patch(url, data, config))
  },
  delete: async (url, config = {}) => {
    const priority = config.priority || 9
    if (config.stopSchedule) {
      limiter = await stopAndCreateNewLimiter(limiter, bottleneckSettings)
    }
    return limiter.schedule({ priority }, () => client.delete(url, config))
  }
}
