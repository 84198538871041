export default [{
  label: 'Hide companies',
  value: item => item.entity_type !== 'contact'
}, {
  label: 'Hide people',
  value: item => item.entity_type !== 'person'
}, {
  label: 'Hide duplicates',
  value: item => item.duplicates ? !item.duplicates.length : true
}]
