import uuid from 'uuid/v1'
import http from '@/services/http'
import fields from './fields'
import { make } from 'vuex-pathify'
import { isEqual } from '../utils'
import { startsWith } from 'lodash'

import SuperOffice from '@/store/SuperOffice'

const rightsMapping = {
  PhoneNumber: 'Phone'
}

/* exports */

export { fields }

export const reduceTruth = (contact, prospect, canUpdateField) => {
  const keys = Object.keys(contact)
  return keys.reduce((results, key) => {
    results[key] = canUpdateField(key) ? !isEqual(contact[key], prospect[key]) : false
    return results
  }, {})
}

/* store module */

const state = {
  ready: false,
  busy: false,
  id: 0,
  current: null,
  options: [],
  rights: null,
  prospect: null
}

const getters = {
  ...make.getters(state),
  getByUuid: state => uuid => state.options.find(i => i.uuid === uuid),
  canUpdate: state => state.rights ? state.rights.table.Mask >= 3 : false,
  canUpdateField: state => field => {
    if (!state.rights) {
      return false
    }
    const right = state.rights.fields[field] || state.rights.fields[rightsMapping[field]]
    if (right) {
      return right.FieldRight.Mask >= 3
    }
    return true
  },
  udefs: (state, getters, rootState, rootGetters) => {
    return rootGetters['update/general'].CompanyFields
      .filter(i => i.type !== 'list')
      .filter(i => startsWith(i.key, 'udef.'))
  }
}

const actions = {
  setExisting: ({ commit }, contactId) => {
    return new Promise(resolve => {
      const id = Number(contactId)
      if (!!id && !isNaN(id)) {
        http.get(`/api/contact/${id}`).then(response => {
          commit('current', { ...response.data, id })
          return http.get(`/api/contact/${id}/rights`)
        }).then(response => {
          commit('rights', response.data)
          commit('ready', true)
          resolve({ contact: true })
        })
      } else {
        commit('ready', true)
        resolve({ contact: false })
      }
    })
  },
  getOptions: ({ commit, getters, state }) => {
    commit('busy', true)
    const { current } = state
    const { id } = current
    return http.get(`/api/contact/${id}/prospects`).then(response => {
      // const providers = response.data
      // const options = providers.reduce((results, provider) => {
      //   const companies = provider.Companies.map(company => {
      //     return { ...company, uuid: uuid(), isEqual(fields, current, company) }
      //   })
      //   return [...results, ...companies]
      // }, [])
      const options = response.data.map(i => ({ ...i, uuid: uuid() }))
      if (!options.length) {
        return Promise.reject(new Error(`${current.Name}`))
      } else {
        commit('options', options)
      }
    }).finally(() => {
      commit('busy', false)
    })
  },
  resetOptions: ({ commit }) => {
    commit('options', [])
  },
  postUpdate: ({ commit, getters }, { prospect, fields }) => {
    commit('busy', true)
    const { id } = getters.current
    return http.post(`/api/contact/${id}`, { prospect, fields }).then(response => {
      SuperOffice.ClientCrossMessaging.refresh()
    }).finally(() => {
      commit('busy', false)
    })
  },
  convert: ({ commit }, prospect) => {
    commit('busy', true)
    return http.post('/api/prospect/convert', prospect).then(response => {
      commit('prospect', response.data)
    }).finally(() => {
      commit('busy', false)
    })
  }
}

const mutations = {
  ...make.mutations(state)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
