<template>
  <GenericModal
   :width="1000"
   title="Send feedback"
   class="send-feedback"
  >
    <el-input
     class="feedback-input"
     v-model="body"
     type="textarea"
     :rows="15"
    ></el-input>
    <template slot="footer">
      <el-button
       type="success"
       :disabled="!body.length"
       @click="handleSendFeedback"
      >Submit feedback</el-button>
    </template>
  </GenericModal>
</template>

<script>
import GenericModal from '@/components/GenericModal'
import { call } from 'vuex-pathify'

export default {
  components: {
    GenericModal
  },
  data () {
    return {
      body: ''
    }
  },
  methods: {
    ...call(['sendFeedback']),
    async handleSendFeedback () {
      await this.sendFeedback(this.body)
      this.$router.go(-1)
    }
  }
}

</script>

<style scoped>
.feedback-input>>>textarea {
  padding: 15px;
}

</style>
