import Vue from 'vue'
import {
  Row,
  Col,
  Button,
  Input,
  InputNumber,
  Switch,
  Loading,
  Tooltip,
  Select,
  Option,
  Notification,
  Card,
  Dialog,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Divider,
  Message,
  Table,
  TableColumn,
  Link,
  Checkbox,
  CheckboxGroup,
  RadioGroup,
  Radio,
  Collapse,
  CollapseItem
} from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

locale.use(lang)

Vue.use(Row)
Vue.use(Col)
Vue.use(Button)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Switch)
Vue.use(Loading.directive)
Vue.use(Tooltip)
Vue.use(Select)
Vue.use(Option)
Vue.component(Notification.name, Notification) // fix empty Notification popping up on app load
Vue.use(Card)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Divider)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Link)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Collapse)
Vue.use(CollapseItem)

Vue.prototype.$notify = Notification
Vue.prototype.$message = Message

Vue.prototype.$ELEMENT = { size: 'mini' }
