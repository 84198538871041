import './style/main.css'
import './style/superoffice-icons.css'

import Vue from 'vue'
// 'store' must be imported here (ref. 'vuex-pathify' config "bug")
import store from './store'
import App from './App.vue'
import router from './router'

import './element-ui'
import './style/element-override.css'

import FlagIcon from 'vue-flag-icon'

import i18n from './locales'

Vue.use(FlagIcon)

Vue.config.productionTip = false

router.onError(err => {
  Vue.prototype.$notify.error({
    title: 'Opps!',
    message: err.message,
  })
  if (err.fallback) {
    router.replace({ name: err.fallback })
  } else if (err.domain) {
    router.replace({ name: err.domain })
  }
})

new Vue({
  store,
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
