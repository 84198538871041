export default {
  noContext: 'Mangler valgt firma eller person...',
  noContact: 'Firma er ikke valgt',
  noPerson: 'Person er ikke valgt',
  searchContact: 'Søk etter oppdateringer for firma',
  searchPerson: 'Søk etter oppdateringer for person',
  noResultsTitle: 'Ingen treff',
  noResultsMessage: 'Fant ingen treff på',
  fields: {
    name: 'Navn',
    firstname: 'Fornavn',
    lastname: 'Etternavn',
    org_no: 'Org.nr.',
    phone: 'Telefonnr.',
    url: 'Webside',
    street_address: 'Gateadresse',
    street_zipcode: 'Postnummer',
    street_city: 'Sted',
    postal_address: 'Postadresse',
    postal_zipcode: 'Postnummer',
    postal_city: 'Sted',
    source: 'Datakilde'
  },
  selectColumns: {
    field: 'Felt',
    existing: 'Verdi nå',
    select: 'Skal oppdatere',
    new: 'Oppdatere til'
  },
  update: 'Oppdater',
  cancel: 'Avbryt'
}
