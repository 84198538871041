<template>
  <div class="contact flex-start">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'contact'
}

</script>
<style scoped>
</style>
