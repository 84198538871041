<template>
  <div class="select-values-page">
    <table class="select-values footer-space">
      <thead>
        <tr>
          <th>{{$t('update.selectColumns.field')}}</th>
          <th>{{$t('update.selectColumns.existing')}}</th>
          <th>{{$t('update.selectColumns.select')}}</th>
          <th>{{$t('update.selectColumns.new')}}</th>
        </tr>
      </thead>
      <tbody>
        <tr
         v-for="field in fields"
         :key="field"
         @click="toggleSelected(field)"
         :class="{ 'selected': values[field], 'disabled': !canUpdateField(field), 'separate': shouldSeparate(field) }"
        >
          <td class="field">{{$t(`update.fields.${field}`)}}</td>
          <td class="existing">{{person[field]}}</td>
          <td class="select">
            <el-switch
             :value="values[field]"
             active-color="#0a5e58"
             :disabled="!canUpdateField(field)"
            ></el-switch>
          </td>
          <td class="prospect">{{prospect[field]}}</td>
        </tr>
        <tr
         v-for="(field, $index) in udefs"
         :key="field.key"
         @click="toggleSelected(field.key)"
         :class="{ 'selected': values[field.key], 'disabled': !canUpdateField(field.key), 'separate': $index === 0 }"
        >
          <!-- <td class="field">{{$t(`update.fields.${field.key}`)}}</td> -->
          <td class="field">{{field.label}}</td>
          <td class="existing">{{person[field.key]}}</td>
          <td class="select">
            <el-switch
             :value="values[field.key]"
             active-color="#0a5e58"
             :disabled="!canUpdateField(field.key)"
            ></el-switch>
          </td>
          <td class="prospect">{{prospect[field.key]}}</td>
        </tr>
      </tbody>
    </table>
    <p class="datasource footer-space"><i>Datasource: {{prospect.source}}</i></p>
    <transparent-footer>
      <el-button
       type="default"
       @click="cancel"
      >{{$t('update.cancel')}}</el-button>
      <el-button
       type="success"
       class="update float-right"
       :disabled="!selectionExists || !canUpdate"
       @click="handlePostUpdate"
      >{{$t('update.update')}}</el-button>
    </transparent-footer>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import TransparentFooter from '@/components/TransparentFooter'
import { fields, reduceTruth } from '@/store/modules/update/person'
import { endsWith } from 'lodash'

export default {
  name: 'select-values',
  props: ['uuid'],
  components: {
    TransparentFooter
  },
  data () {
    // fetch current person and prospect
    const { uuid } = this
    const person = this.$store.getters['update/person/current']
    const prospect = this.$store.getters['update/person/getByUuid'](uuid)
    const canUpdateField = this.$store.getters['update/person/canUpdateField']
    // set selected fields state
    const values = reduceTruth(person, prospect, canUpdateField)
    return {
      person,
      prospect,
      fields,
      values
    }
  },
  computed: {
    ...mapGetters('update/person', ['canUpdate', 'canUpdateField', 'udefs']),
    selectionExists () {
      return !!Object.keys(this.values).filter(key => !!this.values[key]).length
    }
  },
  methods: {
    ...mapActions('update/person', ['postUpdate']),
    cancel () {
      this.$router.go(-1)
    },
    toggleSelected (field) {
      if (this.canUpdateField(field)) {
        this.values[field] = !this.values[field]
      }
    },
    handlePostUpdate () {
      const { prospect, values } = this
      const fields = Object.keys(values).filter(field => !!values[field])
      this.postUpdate({ prospect, fields })
    },
    shouldSeparate (field) {
      return endsWith(field, 'Address')
    }
  }
}

</script>
<style scoped>
.select-values-page {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  flex-direction: column;
}

.update {
  float: right;
}

.datasource {
  padding-left: 10px;
  color: #8e8e8e;
}

</style>
