<template>
  <el-row class="button-bar">
    <div class="button-bar__inner">
      <slot></slot>
    </div>
  </el-row>
</template>
<script>
export default {}

</script>
<style scoped>
.button-bar {
  position: absolute;
  background: #f1f1f1;
  left: 0;
  right: 0;
  bottom: 0;
  height: 35px;
  margin-top: 10px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #e0e0e0;
}

.button-bar__inner {
  padding: 1px 10px;
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
  box-sizing: border-box;
}

.button-bar>>>.el-button {
  border: 1px solid transparent;
}

.button-bar>>>.el-button:disabled {
  background: inherit;
  color: #b0b0b0;
}

.button-bar>>>.el-button:disabled:hover {
  cursor: not-allowed;
}

.button-bar>>>.el-button:hover {
  background: #fff;
  border: 1px solid #e1e1e1;
  cursor: pointer;
}

</style>
